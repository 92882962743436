import React from "react";
import author from "../profile.png";

const AboutMe = () => {
  return (
    <div id="about" className="container py-5">
      <div className="row">
        <div className="col-lg-6 col-xm-12">
          <div className="profile-wrap mb-5">
            <img className="profile-pic" src={author} alt="Profile Picture" />
          </div>
        </div>
        <div className="col-lg-6 col-xm-12">
          <h1 className="about-heading">About Me</h1>
          <p>
            I am an experienced software engineer who constantly seeks out
            innovative solutions to everyday problems. In my 3 years in this
            industry, I've honed my analytical thinking and collaboration
            skills, and I love working with a team. I've also had the
            opportunity to serve as the lead for multiple projects for a budget
            ranging up to a million dollars. Along side with my professional
            career, I successfully run my own digital marketing and web
            designing business called Siteon.
          </p>
          <p>
            I am a quick learner and very passionate about programming different
            applications in various languages. Most of the programming languages
            I know were learned from self-teaching myself by taking online
            courses and reading other developer’s codes on GitHub during my
            spare time.
          </p>
          <p>A few of my side projects include:</p>
          <ul>
            <li>
              Super Mario Bros game which was my final project in high school.
            </li>
            <li>
              Customer Invoicing Database Application which was a side project
              for my parents catering business.
            </li>
            <li>20+ Websites made for my Siteon Clients.</li>
          </ul>
          <p>
            Apart from my career, I enjoy playing video games, volleyball, table
            tennis, and learn new songs on my guitar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
