import React from "react";
import roofing from "../project1.png";
import project2 from "../project2.png";
import project3 from "../project3.png";
import project4 from "../project4.png";
import project5 from "../project5.png";
import project6 from "../project6.png";
import project7 from "../project7.png";
//FONT AWESOME IMPORTS
import { FontAwesome, FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
//REACT POPUPBOX
import { PopupboxManager, PopupboxContainer } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";

const Portfolio = () => {
  //Project 1
  const openPopupboxProject1 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={roofing}
          alt="Project 1"
        />
        <b>Project 1 = Roofing website made for a Siteon customer.</b>
      </>
    );
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: "Able Group Roofing",
        },
        fadeIn: true,
        fadeInSpeed: 500,
      },
    });
  };

  //Project 2
  const openPopupboxProject2 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={project2}
          alt="Project 2"
        />
        <b>
          Project 2 = Gas and Water Service website made for a client in India.
        </b>
      </>
    );
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: "Universal Technician",
        },
        fadeIn: true,
        fadeInSpeed: 500,
      },
    });
  };

  //Project 3
  const openPopupboxProject3 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={project3}
          alt="Project 3"
        />
        <b>
          Project 3 (In Progress) = Made a roofing website for a Siteon
          customer.
        </b>
      </>
    );
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: "Toronto Master Roofing",
        },
        fadeIn: true,
        fadeInSpeed: 500,
      },
    });
  };

  //Project 4
  const openPopupboxProject4 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={project4}
          alt="Project 4"
        />
        <b>Project 4 = Made a website for a Siteon client in California.</b>
      </>
    );
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: "Jyotislight",
        },
        fadeIn: true,
        fadeInSpeed: 500,
      },
    });
  };

  //Project 5
  const openPopupboxProject5 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={project5}
          alt="Project 5"
        />
        <b>
          Project 5 = Website made for my parents catering business to display
          the menu for the customers.
        </b>
      </>
    );
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: "Maitri Daily Tiffin Services",
        },
        fadeIn: true,
        fadeInSpeed: 500,
      },
    });
  };

  //Project 6
  const openPopupboxProject6 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={project6}
          alt="Project 6"
        />
        <b>Project 6 = Made a website for my own web dev business.</b>
      </>
    );
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: "Siteon Corp.",
        },
        fadeIn: true,
        fadeInSpeed: 500,
      },
    });
  };

  //Project 7
  const openPopupboxProject7 = () => {
    const content = (
      <>
        <img
          className="portfolio-image-popupbox"
          src={project7}
          alt="Project 7"
        />
        <b>
          Project 7 (In Progress) = Made a website for my friend's photography
          gallery.
        </b>
      </>
    );
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: "Aditya Patel's Photography",
        },
        fadeIn: true,
        fadeInSpeed: 500,
      },
    });
  };

  return (
    <div id="portfolio" className="portfolio-wrapper">
      <div className="container">
        <h1 className="text-upper text-center py-5">Portfolio</h1>
        <div className="image-box-wrapper row justify-content-center">
          <div className="portfolio-image-box" onClick={openPopupboxProject1}>
            <img className="portfolio-image" src={roofing} alt="Project 1" />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>
          {/* - */}
          <div className="portfolio-image-box" onClick={openPopupboxProject2}>
            <img className="portfolio-image" src={project2} alt="Project 1" />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>
          {/* - */}
          <div className="portfolio-image-box" onClick={openPopupboxProject3}>
            <img className="portfolio-image" src={project3} alt="Project 1" />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>
          {/* - */}
          <div className="portfolio-image-box" onClick={openPopupboxProject4}>
            <img className="portfolio-image" src={project4} alt="Project 1" />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>
          {/* - */}
          <div className="portfolio-image-box" onClick={openPopupboxProject5}>
            <img className="portfolio-image" src={project5} alt="Project 1" />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>
          {/* - */}
          <div className="portfolio-image-box" onClick={openPopupboxProject6}>
            <img className="portfolio-image" src={project6} alt="Project 1" />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>
          {/* - */}
          <div className="portfolio-image-box" onClick={openPopupboxProject7}>
            <img className="portfolio-image" src={project7} alt="Project 1" />
            <div className="overflow"></div>
            <FontAwesomeIcon className="portfolio-icon" icon={faSearchPlus} />
          </div>
        </div>
      </div>
      <PopupboxContainer />
      <PopupboxContainer />
      <PopupboxContainer />
      <PopupboxContainer />
      <PopupboxContainer />
      <PopupboxContainer />
      <PopupboxContainer />
    </div>
  );
};

export default Portfolio;
