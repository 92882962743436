import React from "react";
import { FontAwesome, FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faDesktop, faFileCode } from "@fortawesome/free-solid-svg-icons";
import react from "../Images/Languages/react.png";
import python from "../Images/Languages/python.png";
import java from "../Images/Languages/java.png";
import csharp from "../Images/Languages/csharp.png";
import html from "../Images/Languages/html.png";
import css from "../Images/Languages/css.png";
import js from "../Images/Languages/js.png";
import node from "../Images/Languages/node.png";

const Services = () => {
  return (
    <div id="services" className="services">
      <h1 className="py-5">My Tech Stack</h1>
      <div className="container">
        <div className="row">
          {/*  */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div>
                <img src={react} alt="" className="language-logo" />
              </div>
              <h3 className="language-name">React.js</h3>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div>
                <img src={python} alt="" className="language-logo" />
              </div>
              <h3 className="language-name">Python</h3>
            </div>
          </div>

          {/*  */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div>
                <img src={node} alt="" className="language-logo" />
              </div>
              <h3 className="language-name">Node.js</h3>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div>
                <img src={csharp} alt="" className="language-logo" />
              </div>
              <h3 className="language-name">C#</h3>
            </div>
          </div>
        </div>
        <div className="row">
          {/*  */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div>
                <img src={java} alt="" className="language-logo" />
              </div>
              <h3 className="language-name">Java</h3>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div>
                <img src={html} alt="" className="language-logo" />
              </div>
              <h3 className="language-name">HTML</h3>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div>
                <img src={css} alt="" className="language-logo" />
              </div>
              <h3 className="language-name">CSS</h3>
            </div>
          </div>
          {/*  */}
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="box">
              <div>
                <img src={js} alt="" className="language-logo" />
              </div>
              <h3 className="language-name">JavaScript</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
