import React from "react";
import microart from "../Images/microart.png";
import vexos from "../Images/vexos.png";
import sunlife from "../Images/sunlife.png";
import kinectrics from "../Images/kinectrics.png";
import siteon from "../Images/siteon.png";
import D2L from "../Images/D2L.png";

const Experience = () => {
  return (
    <div>
      <div id="experience" className="experience">
        <div className="d-flex justify-content-center my-5">
          <h1>Experience</h1>
        </div>
        <div className="container experience-wrapper">
          {/* - */}
          <div className="timeline-block timeline-block-left">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>05/2022 - 11/2022</h3>
              <img
                src={D2L}
                alt="D2L Desire2Learn"
                className="exp-company-img"
              />
              <p>
                <b>Software Developer</b> - Kitchener, ON (Remote)
              </p>
              <ul>
                <li>
                  Wrote and delivered reliable code through integration, test
                  automation, and in-depth code reviews.
                </li>
                <li>
                  Enhanced our backend and frontend framework to support new
                  feature requests and performance requirements.
                </li>
                <li>
                  Collaborated with side design engineers to support user
                  stories from clients.
                </li>
                <li>
                  Implemented automated unit tests for new features integrated
                  and defects fixed by our team.
                </li>
                <li>
                  <b>
                    Tech Stack: <br />
                  </b>
                  <p className="tech-stack">
                    C#, .Net, Node.js, SQL, Grafana, Postman, Git
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* - */}
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2020 - 2021</h3>
              <img
                src={kinectrics}
                alt="Kinectrics Inc."
                className="exp-company-img"
              />
              <p>
                <b>Project Controls / Developer</b> - Toronto, ON
              </p>
              <ul>
                <li>
                  Part of a in-house web application project team which controls
                  the schedules of projects in a multiple departments which uses
                  charts to visualize large data.
                </li>
                <li>
                  Apply project management principles to manage Bruce Power's
                  database with projects data.
                </li>
                <li>
                  Create codes which uses Bruce Power's data to generate reports
                  that Project Specialists can use to finish their tasks.
                </li>
                <li>
                  Debug premade codes from past interns and make them efficient.
                </li>
                <li>Add onto projects made by past interns as requested.</li>
                <li>
                  <b>
                    Tech Stack: <br />
                  </b>
                  <p className="tech-stack">
                    React.js, C#, Node.js, Python, Microsoft VBA, SQL, Microsoft
                    Access
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* - */}
          <div className="timeline-block timeline-block-left">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2019 - Present</h3>
              <img
                src={siteon}
                alt="SiteOn Corp."
                className="exp-company-img"
              />
              <p>
                <b>Founder / Web Developer</b> - Markham, ON
              </p>
              <ul>
                <li>
                  Design and Develop websites for clients in various business
                  backgrounds.
                </li>
                <li>
                  Interface directly with Clients/CEO to discuss their business
                  needs and requirements to make a website and boost their
                  rankings on google.
                </li>
                <li>
                  Manage the front end and backend system to host websites
                  online.
                </li>
                <li>
                  Manage customer's invoiving, cashflow, finances of my business
                  single handedly.
                </li>
                <li>
                  Create several diagrams to understand and visualize the
                  website prior to development so the customer and myself are on
                  the same page.
                </li>
                <li>
                  Apply software development cycles learned throughout
                  university to the websites made for each customer.
                </li>
                <li>
                  <b>
                    Tech Stack: <br />
                  </b>
                  <p className="tech-stack">
                    HTML, CSS, JavaScript, Wordpress, Bootstrap, Third Party
                    Plugins
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* - */}
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2019</h3>
              <img
                src={sunlife}
                alt="SunLife Financial"
                className="exp-company-img"
              />
              <p>
                <b>Information Security Analyst</b> - North York, ON
              </p>
              <ul>
                <li>
                  Assist developers in creating a in-house web application to
                  monitor the logs of the organization to help detect and
                  prevent cybersecurity vulnerabilities.
                </li>
                <li>Develop scripts to automate tasks for the employees.</li>
                <li>
                  Interface with Developers and Security Analysts daily to
                  ensure work flow is running smooth and to investigate
                  escalated threats of the organization.
                </li>
                <li>
                  Assigned to monitor the logs of the organization using SIEM
                  investigations through IBM Qradar
                </li>
                <li>Learned 2 new languages over the term. (Perl, C#)</li>
                <li>
                  <b>
                    Tech Stack: <br />
                  </b>
                  <p className="tech-stack">
                    ASP.NET, C#, Python, Java, Perl, IBM QRadar, C++
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {/* - */}
          <div className="timeline-block timeline-block-left">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2018</h3>
              <img src={vexos} alt="Vexos Inc." className="exp-company-img" />
              <p>
                <b>SMT Machine Operator</b> - Markham, ON
              </p>
              <ul>
                <li>
                  Program the SMT and Solder machine with precise specifications
                  as requested by the Customer and Project Manager.
                </li>
                <li>
                  Operate the SMT Machine single handedly or as a pair to place
                  the electrical components onto the PCB in its exact location.
                </li>
                <li>
                  Run the PCB solder printer for each board making sure the
                  correct solder is used and there arent any bridges being made
                  that would lead to a short circuit.
                </li>
                <li>
                  QA all boards prior and post of placement of the components to
                  ensure all the parts are in the correct location and
                  orientation.
                </li>
                <li>
                  Assist in designing the drawings for the SMT machine program.
                </li>
                <li>
                  Generate reports to visualize overall quality of the
                  production department.
                </li>
              </ul>
            </div>
          </div>
          {/* - */}
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>Feb 2016 - Apr 2018</h3>
              <img
                src={microart}
                alt="Microart Services Inc."
                className="exp-company-img"
              />
              <p>
                <b>SMT Machine Operator / Tester</b> - Markham, ON
              </p>
              <ul>
                <li>
                  Program the SMT and Solder machine with precise specifications
                  as requested by the Customer and Project Manager.
                </li>
                <li>
                  Operate the SMT Machine single handedly or as a pair to place
                  the electrical components onto the PCB in its exact location.
                </li>
                <li>
                  Run the PCB solder printer for each board making sure the
                  correct solder is used and there arent any bridges being made
                  that would lead to a short circuit.
                </li>
                <li>
                  QA all boards prior and post of placement of the components to
                  ensure all the parts are in the correct location and
                  orientation.
                </li>
                <li>
                  Assist in designing the drawings for the SMT machine program.
                </li>
                <li>
                  Generate reports to visualize overall quality of the
                  production department.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
