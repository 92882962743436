import React from "react";
import uoit from "../Images/uoit.png";
import york from "../Images/YorkUni.png";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-bootstrap";

const Education = () => {
  return (
    <div id="education" className="education">
      <div class="container">
        <h1 className="education-header">Education</h1>
        <div class="row">
          <div className="col-lg-6 col-xm-12">
            <img src={uoit} alt="" />
          </div>
          <div className="col-lg-6 col-xm-12">
            <div className="uni-info">
              <b className="uni-title">Ontario Tech University</b>
              <p className="major">Major in Software Engineering</p>
              <p>2017 - 2022</p>
              <div className="cummulative-bar">
                <p>
                  <b>Cummulative GPA: 3.7</b>
                </p>
                <ProgressBar now="86" animated />
              </div>
            </div>
          </div>
          <div class="col-lg-12"></div>
        </div>
        <div class="row">
          <div className="col-lg-6 col-xm-12">
            <img src={york} alt="" />
          </div>
          <div className="col-lg-6 col-xm-12">
            <div className="uni-info">
              <b className="uni-title">
                York University Continuation of Studies
              </b>
              <p className="major">Certification in DevOps</p>
              <p>2022 - Present (Expected to finish 2023)</p>
            </div>
          </div>
          <div class="col-lg-12"></div>
        </div>
      </div>
    </div>
  );
};

export default Education;
