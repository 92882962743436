import React from "react";
import Typed from "react-typed";

const Header = () => {
  return (
    <div id="home" className="header-wraper">
      <div className="main-info">
        <canvas></canvas>
        <h1>Hello! My name is Anuj Patel</h1>
        <Typed
          className="typed-text"
          strings={["Founder of SiteOn", "Software Developer"]}
          typeSpeed={40}
          backSpeed={60}
          loop
        />
        <a
          href="https://drive.google.com/file/d/15MV6WHwoyKCz6XxHDUvouOiJ535N92_l/view?usp=sharing"
          className="btn-main-offer"
          target="_blank"
        >
          View My Resume
        </a>
      </div>
    </div>
  );
};

export default Header;
